import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PollComponent } from './poll/poll.component';
import { PollListComponent } from './poll-list/poll-list.component';

const routes: Routes = [
  { path: '', component: PollListComponent },
  { path: 'poll/:id/:question', component: PollComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
