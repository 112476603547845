<div *ngIf="!poll">
  Loading...
</div>
<div *ngIf="poll">
  <h1 class="text-center mb-5">{{poll.title}}</h1>
  <app-question [presenter]="questions[currentQuestion]"
                [hasPrev]="currentQuestion &gt; 0"
                [hasNext]="currentQuestion &lt; poll.questions.length - 1"
                (choiceValidated)="choiceValidated()"
                (navigate)="navigate($event)">
  </app-question>
</div>
