import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Question } from '../../../api/poll';

export class QuestionPresenter {
  question: Question;
  validated: boolean;
  choice: number;
}

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html'
})
export class QuestionComponent implements OnInit {
  @Input() presenter: QuestionPresenter;
  @Input() hasPrev: boolean;
  @Input() hasNext: boolean;
  @Output() choiceValidated = new EventEmitter();
  @Output() navigate = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  get totalVotes(): number {
    const totalVotes = this.presenter.question.choices.map(c => c.votes).reduce((acc, v) => acc + v);
    return totalVotes === 0 ? 1 : totalVotes;
  }

  validate(): void {
    this.presenter.validated = true;
    this.choiceValidated.next();
    this.presenter.question.choices[this.presenter.choice].votes++;
  }
}
