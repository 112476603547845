<nav class="navbar navbar-expand navbar-dark bg-dark">
  <a class="navbar-brand" [routerLink]="['']">Polling App</a>
</nav>

<ngb-alert *ngFor="let alert of alerts; let i = index" [type]="alert.type" dismissible="true" (close)="closeAlert(i)">
  {{alert?.msg}}
</ngb-alert>

<div class="container-md">
  <router-outlet></router-outlet>
</div>
