<h3 class="text-center mb-4">{{presenter.question.question}}</h3>
<ul *ngIf="!presenter.validated" class="list-group">
  <li *ngFor="let choice of presenter.question.choices; let i = index"
    [class]="'list-group-item list-group-item-action ' + (i === presenter.choice ? 'active' : '')"
    (click)="presenter.choice = i">
    {{choice.choice}}
  </li>
</ul>
<ul *ngIf="presenter.validated" class="list-group">
  <li *ngFor="let choice of presenter.question.choices; let i = index"
    [class]="'list-group-item ' + (i === presenter.choice ? 'list-group-item-secondary' : '')">
    {{choice.choice}}
    <ngb-progressbar [showValue]="true" type="success" [value]="choice.votes / totalVotes * 100"></ngb-progressbar>
  </li>
</ul>

<button *ngIf="!presenter.validated && presenter.choice !== -1" (click)="validate()" class="btn btn-primary btn-lg btn-block mt-4">Validate</button>
<button *ngIf="hasPrev" (click)="navigate.next(-1)" class="btn btn-primary mt-4 mr-3">Previous Question</button>
<button *ngIf="hasNext && presenter.validated" (click)="navigate.next(1)" class="btn btn-primary mt-4">Next Question</button>
