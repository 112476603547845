import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from './alert.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PollListComponent } from './poll-list/poll-list.component';
import { PollComponent } from './poll/poll.component';
import { QuestionComponent } from './question/question.component';

@NgModule({
  declarations: [
    AppComponent,
    PollListComponent,
    PollComponent,
    QuestionComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    { provide: AlertService, useClass: AlertService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
