import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { from, fromEvent, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Poll, NewVote } from '../../../api/poll';
import { AlertService } from '../alert.service';
import { QuestionPresenter } from '../question/question.component';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html'
})
export class PollComponent implements OnDestroy, OnInit {
  id: string;
  poll: Poll;
  currentQuestion = -1;
  questions: QuestionPresenter[];
  hubConnection: HubConnection;
  newVoteObservable: Observable<NewVote>;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _http: HttpClient,
    private _ngZone: NgZone,
    private _alertService: AlertService) { console.log('in PollComponent constructor'); }

  ngOnInit(): void {
    console.log('in PollComponent ngOnInit');
    this._route.url.pipe(switchMap((urlSegments: UrlSegment[]) => {
      const id = urlSegments[1].path;
      console.log('in url callback for', urlSegments, 'id is currently', this.id);
      this.currentQuestion = urlSegments.length > 2 ? +urlSegments[2].path : -1;
      if (id !== this.id) {
        this.id = id;
        this.initSignalR();
        return this._http.get<Poll>(`/api/poll/${this.id}`);
      } else {
        return from([this.poll]);
      }
    }))
      .subscribe(
        poll => {
          if (poll === this.poll && this.currentQuestion >= 0 && this.currentQuestion < this.questions.length)
            return;
          this.poll = poll;
          this.questions = poll.questions.map(q => ({question: q, choice: -1, validated: false}));
          const previous = JSON.parse(sessionStorage.getItem(poll.id)) as QuestionPresenter[];
          if (previous) {
            let lastValidated = -1;
            for (let i = 0; i < this.questions.length; i++) {
              this.questions[i].validated = previous[i].validated;
              if (this.questions[i].validated)
                lastValidated = i;
              this.questions[i].choice = previous[i].choice;
            }
            while (this.currentQuestion > 0 &&
                   (this.currentQuestion >= this.questions.length || !this.questions[this.currentQuestion - 1].validated)) {
              this.currentQuestion--;
            }
            if (this.currentQuestion === -1) {
              this.currentQuestion = lastValidated === this.questions.length - 1 ? lastValidated : lastValidated + 1;
            }
          } else {
            this.currentQuestion = 0;
          }
          // make sure the current question is in the url
          console.log('navigating now that we found the current question. id', this.id, '; question', this.currentQuestion);
          this._router.navigate(['/poll', this.id, this.currentQuestion]);
        },
        error => {
          console.log(`Error loading poll ${this.id}`, error);
          this._alertService.newAlert.next({
            msg: `Error loading poll ${this.id} : ${error.message}`,
            type: 'danger', dismissible: true
          });
          this._router.navigate(['/']);
        });
  }

  ngOnDestroy(): void {
    console.log('in PollComponent ngOnDestroy');
    this.hubConnection.stop();
  }

  navigate(questionDelta: number): void {
    this.currentQuestion += questionDelta;
    // make sure the current question is in the url
    this._router.navigate(['/poll', this.id, this.currentQuestion]);
  }

  initSignalR(): void {
    this.hubConnection = new HubConnectionBuilder()
        .withUrl(`/api/poll/${this.id}/signalr`)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
    this.hubConnection.onclose(err => console.log('signalR connection closed', err));
    this.newVoteObservable = this.wrapObservable(fromEvent(this.hubConnection, 'newVote'));
    this.newVoteObservable.subscribe(newVote =>
      this.questions[newVote.question].question.choices[newVote.choice].votes = newVote.votes);
    this.hubConnection.start().then(() => console.log('hub connection started'));
  }

  choiceValidated(): void {
    // may need a closure
    const question = this.currentQuestion;
    const choice = this.questions[question].choice;
    // update the poll
    this._http.post<number>(`/api/poll/${this.poll.id}/question/${question}/choice/${choice}/vote`, {})
      .subscribe(
        // we will eventually be notified of the change (including concurrent votes) but we may immediately update
        newVotes => this.questions[question].question.choices[choice].votes = newVotes,
        error => {
          console.log(`Error updating poll ${this.id}`, error);
          this._alertService.newAlert.next({
            msg: `Error updating poll ${this.id} : ${error.message}`,
            type: 'warning', dismissible: true
          });
      });

    sessionStorage.setItem(this.poll.id, JSON.stringify(this.questions));
  }

  // we wrap observables created from signalR callbacks so that the subscribers run in angular zone
  private wrapObservable<T>(observable: Observable<T>): Observable<T> {
    const wrapped = new Observable<T>(subscriber => {
        const innerSub = observable.subscribe(
            t => this._ngZone.run(() => subscriber.next(t)),
            err => this._ngZone.run(() => subscriber.error(err)),
            () => this._ngZone.run(() => subscriber.complete())
        );
        return () => innerSub.unsubscribe();
    });
    return wrapped;
  }
}
