import { Component, OnInit } from '@angular/core';

import { AlertService } from './alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'polling-app';
  alerts: object[] = [];

  constructor(private _alertService: AlertService) {}

  ngOnInit(): void {
    this._alertService.newAlert.subscribe(alert => this.alerts.push(alert));
  }

  closeAlert(alertIndex: number): void {
    this.alerts.splice(alertIndex, 1);
  }
}
