import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Poll } from '../../../api/poll';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-poll-list',
  templateUrl: './poll-list.component.html'
})
export class PollListComponent implements OnInit {
  polls: {id: string, title: string}[] = [];
  isAdmin = false;

  constructor(private _http: HttpClient, private _alertService: AlertService) { }

  ngOnInit(): void {
    this.getPolls();
  }

  getPolls(): void {
    const pollsObservable = this._http.get<Poll[]>('/api/polls');
    pollsObservable.subscribe(
      polls => this.polls = polls.map(p => ({id: p.id, title: p.title})),
      error => {
        console.log('Error loading polls : ', error);
        this._alertService.newAlert.next({msg: `Error loading polls : ${error.message}`, type: 'danger', dismissible: true});
      }
    );
  }
}
